export const MARKETPLACES = {
  us: {
    id: 'ATVPDKIKX0DER',
    domainName: 'www.amazon.com'
  },
  ca: {
    id: 'A2EUQ1WTGCTBG2',
    domainName: 'www.amazon.ca'
  },
  mx: {
    id: 'A1AM78C64UM0Y8',
    domainName: 'www.amazon.mx'
  },
  br: {
    id: 'A2Q3Y263D00KWC',
    domainName: 'www.amazon.br'
  },
  uk: {
    id: 'A1F83G8C2ARO7P',
    domainName: 'www.amazon.co.uk'
  },
  de: {
    id: 'A1PA6795UKMFR9',
    domainName: 'www.amazon.de'
  },
  es: {
    id: 'A1RKKUPIHCS9HS',
    domainName: 'www.amazon.es'
  },
  fr: {
    id: 'A13V1IB3VIYZZH',
    domainName: 'www.amazon.fr'
  },
  be: {
    id: 'AMEN7PMS3EDWL',
    domainName: 'www.amazon.com.be'
  },
  it: {
    id: 'APJ6JRA9NG5V4',
    domainName: 'www.amazon.it'
  },
  nl: {
    id: 'A1805IZSGTT6HS',
    domainName: 'www.amazon.nl'
  },
  pl: {
    id: 'A1C3SOZRARQ6R3',
    domainName: 'www.amazon.pl'
  },
  tr: {
    id: 'A33AVAJ2PDY3EV',
    domainName: 'www.amazon.tr'
  },
  ae: {
    id: 'A2VIGQ35RCS4UG',
    domainName: 'www.amazon.ae'
  },
  in: {
    id: 'A21TJRUUN4KGV',
    domainName: 'www.amazon.in'
  },
  sa: {
    id: 'A17E79C6D8DWNP',
    domainName: 'www.amazon.sa'
  },
  eg: {
    id: 'ARBP9OOSHTCHU',
    domainName: 'egypt.souq.com'
  },
  jp: {
    id: 'A1VC38T7YXB528',
    domainName: 'www.amazon.co.jp'
  }
}
