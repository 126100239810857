export const MEMBERSHIP_STATUSES = {
  CANCELLED_AT_PERIOD_END: 0,
  ACTIVE: 1,
  DECLINED_PAYMENT: 2,
  MEMBERSHIP_EXPIRING_SHORTLY: 3,
  CALCULATING_LAUNCH_LEVEL: 4
}

export const MEMBERSHIP_STATUSES_LOOKUP = {
  0: 'Cancelled At Period End',
  1: 'Active',
  2: 'Declined Payment',
  3: 'Membership Expiring Shortly',
  4: 'Calculating Launch Level'
}
